import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--toporder-prompt-entities-form"
export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        'toporder_prompt_entity[name]': {
          required: true
        },
      }
    })
    this.loadSamples()
    this.checkVariables()
    this.updatedContent()
  }

  loadSamples() {
    $.each($(this.element).data().sample, (k, v) => {
      this.addSample(k, v)
    })
  }

  addSample(key, value) {
    $('#toporder-prompt-entity__samples-tbody').append(`
      <tr data-key='${key}' id="toporder-prompt-entity__samples--${key}">
        <td>${key}</td>
        <td><input class='input-v2' name="toporder_prompt_entity[sample][${key}]" value="${value}" data-action="change->admin--toporder-prompt-entities-form#checkVariables"/></td>
      </tr>
    `)
  }

  updatedContent() {
    let content_keys = []
    if($('#toporder_prompt_entity_content').val().match(/{{[^{}]*}}/g)) {
      content_keys = $('#toporder_prompt_entity_content').val()
      .match(/{{[^{}]*}}/g).map(e => e.replaceAll('{', '').replaceAll('}', ''))
      .filter(e => $(this.element).data().allowedKeys.includes(e))
    }
    console.log(content_keys)
    content_keys = [...new Set(content_keys)]
    let existing_keys = $('#toporder-prompt-entity__samples-tbody tr').map((i, e) => $(e).data().key).get()
    $.each(content_keys, (i, e) => {
      if(!existing_keys.includes(e) && $(this.element).data().allowedKeys.includes(e)) {
        this.addSample(e, '')
      }
    })

    $.each($('#toporder-prompt-entity__samples-tbody tr'), (i, e) => {
      if(!content_keys.includes($(e).data().key)) {
        $(e).remove()
      }
    })

    this.checkVariables()
    $('#toporder-prompt-entity__samples-table').toggle($('#toporder-prompt-entity__samples-table tbody tr').length > 0)
  }

  addVariable() {
    $('#toporder_prompt_entity_content').val($('#toporder_prompt_entity_content').val() + ` {{${$('#toporder-prompt-entity-form__variables-list').val()}}}`)
    this.updatedContent()
  }

  checkVariables() {
    let errors = []
    let inputs = $('#toporder-prompt-entity__samples-tbody input')
    // if(inputs.filter((i, e) => $(e).val().trim().length > 0).length != inputs.length) {
    //   errors.push("Variables non définies")
    // }
    if($('#toporder_prompt_entity_content').val().trim().length == 0) {
      errors.push("Aucun prompt renseigné")
    }
    
    if(errors.length == 0) {
      $('#toporder-prompt-entity-form__launchTest').attr('disabled', false)
      $('#toporder-prompt-entity-form__alert-variables').addClass('d-none')
    } else {
      $('#toporder-prompt-entity-form__launchTest').attr('disabled', true)
      $('#toporder-prompt-entity-form__alert-variables').removeClass('d-none')
      $('#toporder-prompt-entity-form__alert-variables').html(`<i class="fas fa-exclamation-triangle"></i> ${errors.join('. ')}`)
      
    }   
  }

  launchTest() {
    $('#loading_app').addClass('d-flex')
    $.ajax({
      method: 'PUT',
      url: '/admin/toporder_prompt_entities/tester',
      data: $(this.element).serialize()
    }).done(data => {
      $('#loading_app').removeClass('d-flex')
      $('#toporder-prompt-entity-form__output-container').text(data.content)
    })
  }

  insertTemplate() {
    $('#toporder_prompt_entity_content').val(`system:
You are an AI assistant who helps people find information.
As the assistant, you answer questions briefly, succinctly, 
and in a personable manner using markdown and even add some personal flair with appropriate emojis.

# Customer
You are helping {{firstName}} {{lastName}} to find answers to their questions.
Use their name to address them in your responses.

user:
{{question}}`)
    this.updatedContent()
  }

  showInfos() {
    $('.modal-header').text('Informations sur les variables')
    $('.modal-footer').hide()
    $('#modal_rails_5').modal('show')
    $('.modal-body').html(`
      <div class="toporder-prompt-entity__variables-infos">
        <ul>
          <li><code>{{Product-CategoryName}}</code> : <strong>Replace by product's category name</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "Bûche glacée"</p>
        </blockquote>
        <ul>
          <li><code>{{Product-CodeArticle}}</code> : <strong>Replace by product's code article</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "52006"</p>
        </blockquote>
        <ul>
          <li><code>{{Product-Name}}</code> : <strong>Replace by product's name</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "Bûche Saint-Honoré"</p>
        </blockquote>
        <ul>
          <li><code>{{Product-ParentCategoryName}}</code> : <strong>Replace by product's parentcategory name</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "Desserts"</p>
        </blockquote>
        <ul>
          <li><code>{{Product-PPUPrice}}</code> : <strong>Replace by productpriceunit's price</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "10.50€/kg (pro), 15.90€/kg"</p>
        </blockquote>
        <ul>
          <li><code>{{Product-ProductVariatiationName}}</code> : <strong>Replace by all productvariations's name</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "Bûche Saint-Honoré 4 pers, Bûche Saint-Honoré 6 pers, Bûche Saint-Honoré 8 pers'"</p>
        </blockquote>
        <ul>
          <li><code>{{Product-ProductVariatiationPrice}}</code> : <strong>Replace by all productvariations's price</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "20€/art, 30€/art, 40€/art"</p>
        </blockquote>
        <ul>
          <li><code>{{Product-RefFournisseur}}</code> : <strong>Replace by product's RefFournisseur</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "97830"</p>
        </blockquote>
        <ul>
          <li><code>{{Product-RefProduct}}</code> : <strong>Replace by product's refproduct</strong>
            <ul>
              <li>Required filed in context request: <code>ProductUniqueId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "52006, 52007, 52008"</p>
        </blockquote>
        <ul>
          <li><code>{{Shop-BusinessCategoryName}}</code> : <strong>Replace by shop's business category name</strong>
            <ul>
              <li>Required filed in context request: <code>ShopId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "Boulangerie/Pâtisserie"</p>
        </blockquote>
        <ul>
          <li><code>{{Shop-Name}}</code> : <strong>Replace by shop's name</strong>
            <ul>
              <li>Required filed in context request: <code>ShopId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "Boulangerie du port"</p>
        </blockquote>
        <ul>
          <li><code>{{ShopGroup-Name}}</code> : <strong>Replace by shopGroup's title</strong>
            <ul>
              <li>Required filed in context request: <code>ShopGroupId</code></li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>Example: "Groupe des boutiques du port"</p>
        </blockquote>
      </div>
    `)
  }
}
