import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import Utils__TurboModalFormSliderController from 'controllers/utils/turbo_modal_form_slider_controller';
import { Controller } from "@hotwired/stimulus"
import { classAgregator } from 'shared/class_agregator'
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="back--stock--recipe--recipe-form"
export default class extends classAgregator(Controller, Utils__NestedFormController, Utils__TurboModalFormSliderController) {
  static targets = ['nav', 'templateRecipeIngredient', 'contentRecipeIngredient', 'templateRecipeImport', 'contentRecipeImport', 'templateRecipeStep', 'contentRecipeStep']
  connect() {
    this.element['controller'] = this
    this.referentPrice = $('#recipe_referent_price').valF()
    if($('.recipe_vat_rate').length) {
      this.setResaleHT()
    }

    $(this.element).find('.recipe_measure select').on('select2:select',function(){
      let name = $('.recipe_measure :selected').text()
      $('.recipe_quantity .input-group-text').text(name)
      $('.measure_name_cost').text(name)
      $('#recipe_referent_unit').val(name)
    })

    this.initFormSlider()
    setTimeout(() => {
      $.ajax({
        url: `/back/recipes/fetch_form_datas`,
        method: 'get',
        dataType: "json",
        success: (response) => {
          this.ingredients = response.ingredients
          this.base_recipes = response.base_recipes
          this.recipeImportSelect.html(this.populateSource(this.base_recipes))
          this.ingredientSelect.html(this.populateSource(this.ingredients))
          this.loadIngredients()
          this.loadRecipeImports()
          this.calcCost()
        }
      })
    }, 500)
  }

  get ingredientSelect() {
    return $('.recipe-form_ingredients-container select')
  }

  get recipeImportSelect() {
    return $('.recipe-form_base-recipes-container select')
  }

  get recipeSum() {
    return this.sum
  }

  // ---- Nested Fields ----

  addIngredient() {
    this.addAssociation('RecipeIngredient', {id: this.ingredientSelect.val()})
    this.ingredientSelect.find(':selected').prop('disabled', true)
    this.toggleSelect(this.ingredientSelect)
    $('#recipe_ingredients_header').toggleClass('active', $('.recipe-ingredient-fields:visible').length > 0)
  }

  addBaseRecipe() {
    this.addAssociation('RecipeImport', {id: this.recipeImportSelect.val()})
    this.recipeImportSelect.find(':selected').prop('disabled', true)
    this.toggleSelect(this.recipeImportSelect)
    $('#recipe_imports_header').toggleClass('active', $('.recipe-import-fields:visible').length > 0)
  }

  loadIngredients() {
    $.each($('.recipe-ingredient-fields'), (i, element) => {
      let id = $(element).find('.recipe_recipe_ingredients_ingredient_id input').val()
      this.ingredientSelect.find(`option[value="${id}"]`).prop('disabled',true)
      this.decorateIngredient($(element), id)
    })
    this.toggleSelect(this.ingredientSelect)
    $('#recipe_ingredients_header').toggleClass('active', $('.recipe-ingredient-fields:visible').length > 0)
  }

  loadRecipeImports() {
    $.each($('.recipe-import-fields'), (i, element) => {
      let id = $(element).find('.recipe_recipe_imports_recipe_base_id input').val()
      this.recipeImportSelect.find(`option[value="${id}"]`).prop('disabled',true)
      this.decorateRecipeImport($(element), id)
    })
    this.toggleSelect(this.recipeImportSelect)
    $('#recipe_imports_header').toggleClass('active', $('.recipe-import-fields:visible').length > 0)
  }

  toggleSelect(select) {
    if(select.length == 0) { return }
    select.val(select.find('option').not(':disabled').eq(0).val())
    select.parent().parent().toggle(select.find('option').not(':disabled').length > 0)
    if(select.hasClass('select2-hidden-accessible')) {
      updateSelect2(select)
    } else if(select.is(':visible')) {
      initSelect2(select)
    }
    select.trigger('change')
  }

  callbackAfterAddRecipeStep(element, options) { }
  callbackBeforeRemoveRecipeStep(element) {
    return new Promise((resolve, reject) => { resolve() })
  }
  callbackAfterRemoveRecipeStep() {}

  callbackAfterAddRecipeImport(element, options) {
    element.find('.recipe_recipe_imports_quantity input').val(1)
    setTimeout(() => {
      this.decorateRecipeImport(element, options.id)
      this.calcCost()      
    })
  }

  callbackAfterAddRecipeIngredient(element, options) {
    element.find('.recipe_recipe_ingredients_quantity input').val(1)
    setTimeout(() => {
      this.decorateIngredient(element, options.id)
      this.calcCost()   
    })
  }

  callbackBeforeRemoveRecipeImport(element) {
    return new Promise((resolve, reject) => {
      let recipe_import_id = element.find('.recipe_recipe_imports_recipe_base_id input').val()
      this.recipeImportSelect.find(`option[value="${recipe_import_id}"]`).prop('disabled', false)
      this.toggleSelect(this.recipeImportSelect)
      resolve()
    })
  }

  displayIngredientPrices() {
    $.each($('.recipe-ingredient-fields, .recipe-import-fields'), (i, r) => { r.controller.displayPrices() })
  }

  callbackAfterRemoveRecipeImport() {
    this.calcCost()
    $('#recipe_imports_header').toggleClass('active', $('.recipe-import-fields:visible').length > 0)
  }

  callbackBeforeRemoveRecipeIngredient(element) {
    return new Promise((resolve, reject) => {
      let ingredient_id = element.find('.recipe_recipe_ingredients_ingredient_id input').val()
      this.ingredientSelect.find(`option[value="${ingredient_id}"]`).prop('disabled', false)
      this.toggleSelect(this.ingredientSelect)
      resolve()
    })
  }

  callbackAfterRemoveRecipeIngredient() { 
    this.calcCost()
    $('#recipe_ingredients_header').toggleClass('active', $('.recipe-ingredient-fields:visible').length > 0)
  }

  decorateRecipeImport(element, id) {
    let recipe = this.base_recipes[id]
    element.get(0).controller.setPrice(parseFloat(this.base_recipes[id].price))
    element.find('.recipe-ingredient-fields__recipe-import-name').text(recipe.name)
    element.find('.recipe_recipe_imports_recipe_base_id input').val(id)
    element.find('.recipe_recipe_imports_quantity .input-group-text').text(recipe.measure)
  }

  decorateIngredient(element, id) {
    element.get(0).controller.setPrice(parseFloat(this.ingredients[id].price))
    element.find('.recipe-ingredient-fields__ingredient-name').text(this.ingredients[id].name)
    element.find('.recipe_recipe_ingredients_ingredient_id input').val(id)
    element.find('.recipe_recipe_ingredients_quantity .input-group-text').text(this.ingredients[id].referent_unit)
  }

  populateSource(source) {
    let html = ''
    for(const id in source) { html += `<option value='${id}'>${source[id].name}</option>` }
    return html
  }


  // ---- Cost ----

  quantityChanged(e) {
    if ($(e.currentTarget).val().length == 0 || $(e.currentTarget).valF() < 0 || $(e.currentTarget).valF() == 0) {$(e.currentTarget).val(1)}
    this.calcCost()
  }

  setResaleHT() {
    this.resaleHT = (this.referentPrice * 100 / (100 + this.vatRate()))
    $('.recipe_resale_ht').text(this.roundToTwo(this.resaleHT))
  }

  roundToTwo(number) {
    return Math.round(number * 100) / 100
  }

  vatRate() {
    return parseFloat($('.recipe_vat_rate :checked').data().rate)
  }

  vatRateChanged() {
    this.setResaleHT()
    this.calcMargin()
  }

  calcCost() {
    if($('#recipe_quantity').valF() == 0) {return false;}
    this.sum = 0
    $.each($('.recipe-import-fields').not(".deleted"), (index, fields) => {
      let quantity = $(fields).find('.recipe_recipe_imports_quantity input').valF()
      let base_recipe = this.base_recipes[$(fields).find('.recipe_recipe_imports_recipe_base_id input').valF()]
      let price = parseFloat(base_recipe.price)
      this.sum += price * quantity
    })

    $.each($('.recipe-ingredient-fields').not(".deleted"), (index, fields) => {
      let quantity = $(fields).find('.recipe_recipe_ingredients_quantity input').valF()
      let price = parseFloat(this.ingredients[$(fields).find('.recipe_recipe_ingredients_ingredient_id input').valF()].price)
      this.sum += price * quantity
    })

    this.displayIngredientPrices()
    if (this.sum == 0) {
      $('.recipe_cost').text(this.roundToTwo(this.sum))
      $('.unit_cost').text(0)
      $('#recipe_price').val(0)
      $('#recipe_price_total').val(0)
      $('#recipe_margin_abs').val(0)
      $('.display_margin_abs').text(0)
      $('#recipe_margin_rel').val(0)
      $('.display_margin_rel').text(0)
      $('.display_marque_rel').text(0)
    } else {
      $('.recipe_cost').text(this.roundToTwo(this.sum))
      this.unit_cost = this.roundToTwo(this.sum / $('#recipe_quantity').val())
      $('.unit_cost').text(this.unit_cost)
      $('#recipe_price').val(this.unit_cost)
      $('#recipe_price_total').val(this.roundToTwo(this.sum))

      if($('.recipe_vat_rate').length) {
        this.calcMargin()
      }
    }
  }

  calcMargin() {
    
    let margin_abs = this.resaleHT - this.unit_cost
    $('#recipe_margin_abs').val(this.roundToTwo(margin_abs))
    $('.display_margin_abs').text(this.roundToTwo(margin_abs))
    let margin_rel = this.roundToTwo((margin_abs / this.unit_cost) * 100)
    $('#recipe_margin_rel').val(margin_rel)
    $('.display_margin_rel').text(margin_rel)

    let marque_rel = this.roundToTwo((margin_abs / this.resaleHT) * 100)
    $('.display_marque_rel').text(marque_rel)
    $('#recipe_marque_rel').val(marque_rel)
  }
}
