import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--local-shop-borne-setting-form"
export default class extends Controller {
  connect() {
    $(this.element).find('.local_shop_borne_setting_distribution_types label').on('click', (e) => {
      if($(e.currentTarget).parent().find('input.custom-control-input').prop('checked') && $(this.element).find('.local_shop_borne_setting_distribution_types input:checked').length == 1) {
        e.preventDefault()
      }
    })

    this.toggleSpecificSettings()
  }

  toggleSpecificSettings() {
    let mode = $('.local_shop_local_shop_borne_setting_borne_mode :checked').val()
    $(`.local-shop-borne-setting__catalog-only`).toggle(mode == 'catalog')
    $(`.local-shop-borne-setting__scan-only`).toggle(mode == 'scan')

  }
}
